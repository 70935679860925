import React from 'react'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'

class newsDivisions extends React.Component {
  render() {
    return (
      <MDBContainer className="text-small">
        <MDBRow>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.unicomglobal.com" target="_blank">www.unicomglobal.com</a>
            <p className="mb-0 mt-1">UNICOM Global - Assets, capital and investment management</p>
          </MDBCol>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.unicomsi.com" target="_blank">www.unicomsi.com</a>
            <p className="mb-0 mt-1">UNICOM Systems - IBM Mainframe software products</p>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.unicomgov.com" target="_blank">www.unicomgov.com</a>
            <p className="mb-0 mt-1">UNICOM Government (formerly NASDAQ: GTSI) - Government IT solutions</p>
          </MDBCol>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.unicomengineering.com" target="_blank">www.unicomengineering.com</a>
            <p className="mb-0 mt-1">UNICOM Engineering (formerly NASDAQ: NEI) Appliance platform</p>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.unicom.org/" target="_blank">www.unicom.org</a>
            <p className="mb-0 mt-1">UNICOM Science and Technology Parks</p>
          </MDBCol>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.unicomtechnologypark.com" target="_blank">www.unicomtechnologypark.com</a>
            <p className="mb-0 mt-1">UNICOM Technology Park – Innovation Labs in Virginia</p>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.unicomsciencepark.com" target="_blank">www.unicomsciencepark.com</a>
            <p className="mb-0 mt-1">UNICOM Science and Technology Park – Innovation Labs in New Jersey</p>
          </MDBCol>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.unicom-capital.com" target="_blank">www.unicom-capital.com</a>
            <p className="mb-0 mt-1">UNICOM Capital - Business and Financial Services</p>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.usrobotics.com" target="_blank">www.usrobotics.com</a>
            <p className="mb-0 mt-1">USRobotics - Data communications products</p>
          </MDBCol>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.memeo.com" target="_blank">www.memeo.com</a>
            <p className="mb-0 mt-1">Memeo - Enterprise-grade Secure File Sharing for the Cloud</p>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.firetide.com" target="_blank">www.firetide.com</a>
            <p className="mb-0 mt-1">Firetide - Wireless technology solutions for security and transportation</p>
          </MDBCol>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.detec.com" target="_blank">www.detec.com</a>
            <p className="mb-0 mt-1">DETEC - Document composition products</p>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.softlanding.com" target="_blank">www.softlanding.com</a>
            <p className="mb-0 mt-1">SoftLanding Systems - IBM i software products</p>
          </MDBCol>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.macro4.com" target="_blank">www.macro4.com</a>
            <p className="mb-0 mt-1">Macro 4 (formerly LONDON: MAO) - Document Management products</p>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.illustro.com" target="_blank">www.illustro.com</a>
            <p className="mb-0 mt-1">illustro - z/OS and z/VSE software products</p>
          </MDBCol>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.iet-solutions.com" target="_blank">www.iet-solutions.com</a>
            <p className="mb-0 mt-1">iET Solutions - ITSM software products</p>
          </MDBCol>
        </MDBRow>

        <MDBRow>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.eden.com" target="_blank">www.eden.com</a>
            <p className="mb-0 mt-1">Eden - Mergers &amp; Acquisitions, Business &amp; Financial Services, and Real Estate</p>
          </MDBCol>
          <MDBCol md="6" className="entity-pad">
            <a className="effect" href="https://www.cics.com" target="_blank">www.cics.com</a>
            <p className="mb-0 mt-1">CICS.com - Hardware, Software, Outsourcing and Professional Services</p>
          </MDBCol>
        </MDBRow>

       <p className="text-extra-small mt-5"> All trademarks referenced herein are trademarks of their respective companies.</p>
      </MDBContainer>
    )
  }
}

export default newsDivisions