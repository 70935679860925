import React from 'react'
import Link from 'gatsby-link'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'
import { BLOCKS } from '@contentful/rich-text-types'
import { INLINES } from '@contentful/rich-text-types'
import { MDBRow, MDBCol, MDBContainer } from 'mdbreact'

class boilerplateEntities extends React.Component {
  render() {
    return (
      <MDBContainer>
        <MDBRow className="mb-5">
          <MDBCol>
            {documentToReactComponents(this.props.about, {
              renderNode: {
                [BLOCKS.HEADING_2]: (node, children) => (
                  <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large">
                    {children}
                  </h2>
                ),
                [BLOCKS.HEADING_3]: (node, children) => (
                  <h3 className="font-alt font-w-400 letter-spacing-1 text-medium">
                    {children}
                  </h3>
                ),
                [BLOCKS.PARAGRAPH]: (node, children) => (
                  <p className="font-w-400 letter-spacing-1 text-medium" style={{ whiteSpace: 'pre-wrap' }}>
                    {children}
                  </p>
                ),
                [INLINES.HYPERLINK]: (node, children) =>
                  node.data.uri.charAt(0) == '/' ? (
                    <Link to={node.data.uri}>{children}</Link>
                  ) : (
                    <a href={node.data.uri} className="effect" target="_blank">
                      {children}
                    </a>
                  ),
                [BLOCKS.EMBEDDED_ASSET]: (node, children) => (
                  /* need to change style to class and cater for mobile */
                  <img
                    style={{
                      display: 'block',
                      marginLeft: 'auto',
                      marginRight: 'auto',
                      width: '50%',
                      paddingTop: '10px',
                      paddingBottom: '20px',
                    }}
                    src={`${node.data.target.fields.file['en-US'].url}?w=400&q=90`}
                    alt={node.data.target.fields.title}
                  />
                ),
              },
            })}
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    )
  }
}

export default boilerplateEntities